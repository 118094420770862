import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="welcome" mdxType="Subpage">
      <h3>{`Welcome!`}</h3>
      <p>{`This course will challenge you to push your skills as a designer as you learn more about how to understand your users.
While interactive design is the focus the skills you develop will have an impact on design work in many other fields.`}</p>
      <p>{`Note that we'll use the following technologies in this course and learn more using the Resources link on the main menu.`}</p>
      <ul>
        <li parentName="ul">{`Sketch`}</li>
        <li parentName="ul">{`InVision`}</li>
      </ul>
    </Subpage>
    <Subpage slug="connecting" mdxType="Subpage">
      <h3>{`Connecting`}</h3>
      <p>{`Let's start by considering the impact that interactive devices have made in our world by watching this video: `}<a parentName="p" {...{
          "href": "http://vimeo.com/52861634"
        }}>{`Connecting`}</a></p>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      